import '../../App.css'
import HeroSection from '../HeroSection'
import Cards from '../Cards'
import React from 'react'
import Footer from '../Footer'

function Home() {
    return (
        <>
            <HeroSection></HeroSection>
            <Cards></Cards>
            <Footer></Footer>
        </>
    )
}
export default Home