
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/pages/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Services from './components/pages/Services';
import SignUp from './components/pages/SignUp';
import Products from './components/pages/Products';

// import 
function App() {
  return (
    <div >
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/services' element={<Services></Services>}></Route>
          <Route path='/sign-up' element={<SignUp></SignUp>}></Route>
          <Route path='/products' element={<Products></Products>}></Route>
        </Routes>
      </Router>


    </div>
  );
}

export default App;

// 路由https://www.bilibili.com/read/cv18797039
