import React from 'react'
import '../../App.css'

function Products() {
    return (
        <h1 className='products'>Products</h1>
    )
}

export default Products
